import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LazyLoadModal from "./common/LazyLoadModal";
// include global scss
import "./global.scss";
// components
const Home = React.lazy(() => import("./components/home"));
const About = React.lazy(() => import("./components/about"));
const Give = React.lazy(() => import("./components/give"));
const Contact = React.lazy(() => import("./components/contact"));
const GospelTracts = React.lazy(() => import("./components/literature"));
const KnowJesus = React.lazy(() => import("./components/knowJesus"));
const Store = React.lazy(() => import("./components/store"));
const JerusalemChurches = React.lazy(() =>
  import("./components/ministries/jerusalemChurches")
);
const SlumMinistry = React.lazy(() =>
  import("./components/ministries/slumMinistry")
);
const GFJMinistry = React.lazy(() =>
  import("./components/ministries/generationForJesus")
);
const MusicLiteratureMinistry = React.lazy(() =>
  import("./components/ministries/musicLiterature")
);
const MicroMissionMovementMinistry = React.lazy(() =>
  import("./components/ministries/microMissionMovement")
);
const TvMinistry = React.lazy(() =>
  import("./components/ministries/tvMinistry")
);
// shared components
const Footer = React.lazy(() => import("./common/Footer"));
const Header = React.lazy(() => import("./common/Header"));
const PageNotFound = React.lazy(() => import("./common/PageNotFound"));

class Routes extends Component {
  render() {
    return (
      <Router>
        {/* global suspense fallback - starts */}
        <Suspense fallback={<LazyLoadModal />}>
          <div>
            <Header />

            <GuestRoutes />

            <Footer />
          </div>
          {/* global suspense fallback - ends */}
        </Suspense>
      </Router>
    );
  }
}

const GuestRoutes = () => {
  return (
    <Switch>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Route path="/" component={Home} exact />
      {/* </Suspense> */}

      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Route path="/about" component={About} exact />
      {/* </Suspense> */}

      <Route path="/give" component={Give} exact />

      <Route path="/contact-us" component={Contact} exact />

      <Route path="/literature" component={GospelTracts} exact />

      <Route path="/knowJesus" component={KnowJesus} exact />

      <Route path="/store" component={Store} exact />

      <Route exact path="/ministry/churches" component={JerusalemChurches} />
      <Route exact path="/ministry/slum-ministry" component={SlumMinistry} />
      <Route exact path="/ministry/gfj" component={GFJMinistry} />
      <Route
        exact
        path="/ministry/music-literature"
        component={MusicLiteratureMinistry}
      />
      <Route
        exact
        path="/ministry/micro-mission-movement"
        component={MicroMissionMovementMinistry}
      />
      <Route exact path="/ministry/tv" component={TvMinistry} />

      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
