import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./index.css";

//firebase
var config = {
    apiKey: "AIzaSyAdHfJklxHj-TuDDay33-X3CGDK-xy0dT4",
    authDomain: "odm-android-app-development.firebaseapp.com",
    databaseURL: "https://odm-android-app-development.firebaseio.com",
    projectId: "odm-android-app-development",
    storageBucket: "odm-android-app-development.appspot.com",
    messagingSenderId: "829712533106",
};
firebase.initializeApp(config);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
