import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { grey800, darkBlack } from "material-ui/styles/colors";
import Routes from "./routes";

//ui
const muiTheme = getMuiTheme({
  palette: {
    primary1Color: grey800,
    textColor: darkBlack,
    alternateTextColor: grey800,
    accent1Color: grey800,
  },
});

const App = () => {
  return (
    <MuiThemeProvider muiTheme={muiTheme}>
    <Router>
      <Routes />
    </Router>
    </MuiThemeProvider>
  );
};

export default App;
